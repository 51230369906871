import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {isDevMode, LOCALE_ID, NgModule} from '@angular/core';
import {AngularFireMessagingModule} from '@angular/fire/compat/messaging';
import {AngularFireModule} from '@angular/fire/compat';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ControlRealTimeComponent} from './pages/control-real-time/pages/control-real-time.component';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {FooterComponent} from './core/components/footer/footer.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {GlobalAlertComponent} from './core/components/global-alert/global-alert.component';
import {LateralMenuComponent} from './core/components/lateral-menu/lateral-menu.component';
import {LateralMenuIpadComponent} from './core/components/lateral-menu-ipad/lateral-menu-ipad.component';
import {LoginComponent} from './pages/sign-in/login/login.component';
import {MobileAccessComponent} from './core/components/mobile-access/mobile-access.component';
import {NavbarComponent} from './core/components/navbar/navbar.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {QRCodeModule} from 'angularx-qrcode';
import {RegisterComponent} from './pages/sign-in/register/register.component';
import {RouterModule} from '@angular/router';
import {StoreVerificationComponent} from './pages/sign-in/store-verification/store-verification.component';
import {WelcomeComponent} from './core/components/welcome/welcome.component';
import dayGridPlugin from '@fullcalendar/daygrid';
import {environment} from '../environments/environment';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import localeEsAr from '@angular/common/locales/es-AR';
import {CommonModule, registerLocaleData} from '@angular/common';
import timeGridPlugin from '@fullcalendar/timegrid';
import {AngularFireRemoteConfigModule, DEFAULTS, SETTINGS, AngularFireRemoteConfig} from '@angular/fire/compat/remote-config';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {SharedModule} from './shared/shared.module';

registerLocaleData(localeEsAr, 'es-Ar');

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    WelcomeComponent,
    LateralMenuComponent,
    LateralMenuIpadComponent,
    FooterComponent,
    MobileAccessComponent,
    ControlRealTimeComponent,
    StoreVerificationComponent,
    GlobalAlertComponent,
    NotFoundComponent,
    MaintenanceComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    QRCodeModule,
    RouterModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    ConfirmationService,
    MessageService,
    DialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es_Ar',
    },
    {provide: DEFAULTS, useValue: {enableAwesome: true}},
    {      
      provide: SETTINGS, useValue: {fetchTimeoutMillis: 10000, minimumFetchIntervalMillis: 10000}
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private remoteConfig: AngularFireRemoteConfig) {    
    this.remoteConfig.fetchAndActivate();
  }
}
