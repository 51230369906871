import {Injectable} from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { resolve } from 'dns';


//@Injectable()
@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
 constructor(private remoteConfig: AngularFireRemoteConfig) {
    this.remoteConfig.fetchAndActivate();
  }
 
  async getBooleanValueByKey(key) {
    try{
      await this.remoteConfig.fetch();
      await this.remoteConfig.fetchAndActivate().then((fetchedRemotely) => this.logDelFetch(fetchedRemotely))
      const result = await this.remoteConfig.getBoolean(key);
      return result;
    } catch (error) {
      console.log("error on fetching remote config");
    }
      
  }

  logDelFetch(fetchedRemotely) {
    if (fetchedRemotely) {
      console.log('configs were fetched from the firebase backend');              
    } 
  }

      


}
