import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { RemoteConfigService } from 'src/app/services/remote-config.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(
    private router: Router,
    private remoteConfig: RemoteConfigService
  ) {
  }

  async ngOnInit() {
    this.checkMaintenance();
    setInterval(() => {
      this.checkMaintenance();
    }, 60000);
  }

  checkMaintenance() {
    this.getUnderMaintenance();
    // const isInMaintenanceMode = environment.isUnderMaintenance;
    // if (!isInMaintenanceMode) {
    //   this.router.navigate(['']);
    // }
  }


  getUnderMaintenance() {
    this.remoteConfig.getBooleanValueByKey(environment.isUnderMaintenanceFirebaseKey)
    .then(result => {
      //this.isInMaintenance = result;
        if (result) {
          this.router.navigate(['maintenance']);
        } else {
          this.router.navigate(['']);
        }
    });
  }

}
